import './Hobbies.css';
import Palm2 from '../Images/Palm2.png';
import Buster from '../Images/Buster.png';
import Pumpkin from '../Images/Pumpkin.png';
import Love from '../Images/Love.png';
import N64 from '../Images/N64.png';
import Rupee from '../Images/Rupee.png';
import Pebbles from '../Images/Pebbles.png';
import WitchHat from '../Images/WitchHat.png';
import Palm from '../Images/Palm.png';
import Donut from '../Images/Donut.gif';
import Coffee from '../Images/Coffee.png';
import Cougar from '../Images/Cougar.png';
import Moon from '../Images/Moon.png';
import Restoration from '../Images/Restoration.png';


function Hobbies() {
  return (
    <div id="Hobbies" className="Hobbies">
      <header className="Hobbies-header">
        <h2 className="HobbiesTitle">
          Hobbies & Skills
        </h2>
        <div className="HobbiesHeadline">
          <p>Graphic Design</p>
          <div className="HobbiesCompany">
            <p>Emojis:</p>
            <div className="HobbiesDetails">
              <img src={Palm2} className="Palm2" alt="Palm2" />
              <img src={Pumpkin} className="Pumpkin" alt="Pumpkin" />
              <img src={Love} className="Love" alt="Love" />
              <img src={Buster} className="Buster" alt="Buster" />
              <img src={N64} className="N64" alt="N64" />
              <img src={Pebbles} className="Pebbles" alt="Pebbles" />
              <img src={Rupee} className="Rupee" alt="Rupee" />
              <img src={WitchHat} className="WitchHat" alt="WitchHat" />
              <img src={Palm} className="Palm" alt="Palm" />
              <img src={Coffee} className="Coffee" alt="Coffee" />
              <img src={Cougar} className="Cougar" alt="Cougar" />
              <img src={Moon} className="Moon" alt="Moon" /> 
            </div>
            <div className="LargeImageSectionContainer">
              <div className="LargeImageContainer">
                <p>3D Render in Blender:</p>
                <img src={Donut} className="Donut" alt="Donut" loop="infinite" />
              </div>
              <div className="LargeImageContainer">
                <p>Image Restoration:</p>
                <img src={Restoration} className="Restoration" alt="Restoration" /> 
              </div>
            </div>
          </div>
        </div>
        <div className="HobbiesHeadline">
          <p>Music</p>
          <div className="SoundcloudDetails">
            <iframe className="Soundcloud" title="Dont Stop Believing - Journey" width="60%" height="120px" scrolling="no" frameborder="no" disallow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1071720454&color=%232e2c2e&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false"></iframe>
            <iframe className="Soundcloud" title="Take Me To Church - Hozier" width="60%" height="120px" scrolling="no" frameborder="no" disallow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1071663397&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>            
          </div>
        </div>                        
      </header>
    </div>
  );
}

export default Hobbies;
