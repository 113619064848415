import logo from '../Images/logo.svg';
import './Footer.css';

function Footer() {
  return (
    <div id="Footer" className="Footer">
        <div className="FooterContainer">
          <div className="FooterButtonContainer">
            <a className="Me" href="https://drive.google.com/file/d/1cdDbakkQUc_lZ9j7CiSqsz7_yRmghN_u/view?usp=share_link" target="_blank" rel="noopener noreferrer">
              <div className="FooterButtonText" >
              <p className="FooterText">Resume</p>
              </div>
            </a>
          </div>          
          <div className="FooterButtonContainer">
            <a className="Me" href="mailto:courtneynewren@gmail.com?subject=Professional Inquiry" target="_blank" rel="noopener noreferrer"> 
              <div className="FooterButtonText" >
                <p className="FooterText">Courtney<wbr />Newren<wbr />@gmail.com</p>
              </div>
            </a>
          </div> 
          <div className="FooterButtonContainerLogo">
              <img src={logo} className="App-logo" alt="React.js Logo Gif" />
          </div>   
          <div className="FooterButtonContainer">
            <a className="Me" href="https://github.com/courtney1723" target="_blank" rel="noopener noreferrer"> 
              <div className="FooterButtonText" >
                <p className="FooterText">GitHub</p>
              </div>
            </a>
          </div>
          <div className="FooterButtonContainer">
            <a className="Me" href="tel:3038084152" target="_blank" rel="noopener noreferrer"> 
              <div className="FooterButtonText" >
                <p className="FooterText">(303) <wbr />808-4152</p>
              </div>
            </a>
          </div>                               
        </div>
    </div>
  );
}

export default Footer;
