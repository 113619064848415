import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Main/App';
import Work from './Pages/Work';
import Education from './Pages/Education';
import Certifications from './Pages/Certifications';
// import Portfolio from './Pages/Portfolio';
import Navbar from './Main/Navbar';
import Hobbies from './Pages/Hobbies';
import Footer from './Main/Footer';
import reportWebVitals from './reportWebVitals';

console.log(`href: ${window.location.pathname}`);

if (window.location.pathname === "/") {
  ReactDOM.render(
    <React.StrictMode>
      <Navbar />
      <App />
      <Footer />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if (window.location.pathname === "/Work") {
  ReactDOM.render(
    <React.StrictMode>
      <Navbar />
      <Work />
      <Footer />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if (window.location.pathname === "/Education") {
  ReactDOM.render(
    <React.StrictMode>
      <Navbar />
      <Education />
      <Footer />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if (window.location.pathname === "/Certifications") {
  ReactDOM.render(
    <React.StrictMode>
      <Navbar />
      <Certifications />
      <Footer />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if (window.location.pathname === "/Hobbies") {
  ReactDOM.render(
    <React.StrictMode>
      <Navbar />
      <Hobbies />
      <Footer />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else {
  ReactDOM.render(
    <React.StrictMode>
      <Navbar />
      <App />
      <Footer />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
