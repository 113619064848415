import './App.css';
// import logo from '../Images/logo.svg';

function App() {
  return (
    <div id="App" className="App">
      <div className="App-header">
        <div className="introMain"> 
          <a className="ButtonLinkTop" href="tel:3038084152" target="_blank" rel="noopener noreferrer">
            (303)<wbr/> 808<wbr/>-4152
          </a> 
          <wbr/>
          <a className="ButtonLinkTop" href="mailto:courtneynewren@gmail.com?subject=Professional Inquiry" target="_blank" rel="noopener noreferrer"> 
            CourtneyNewren<wbr/>@gmail.com
          </a>
          <wbr/> 
          <a className="ButtonLinkTop" href="https://maps.app.goo.gl/UgwWf2fVbWT9fmsUA" target="_blank" rel="noopener noreferrer">
            Denver, <wbr/>CO, <wbr/>80204
          </a>
        </div>
        <div className="intro">
            Experienced JavaScript, HTML, CSS, and C++ full-stack software developer. Proficient in developing and designing scalable, user-friendly, cloud-based REST APIs. Skilled in high-volume data security and storage, resulting in a 40% reduction in client requests. Excelled in leading multicultural teams. Skilled public speaker, consistently driving sales with a 10% - 30% increase in revenue.
        </div>
        <div className="CoreCompetenciesTitle">
          Core Competencies
        </div>                       
        <div className="CoreCompetencies"> 
          <div className="CoreCompetenciesBox1">
            <div className="CoreCompetenciesBox">
              Attention to Detail
            </div>
            <div className="CoreCompetenciesBox">
              Creative Problem-Solving
            </div> 
            <div className="CoreCompetenciesBox">
              Results Driven
            </div>
          </div>
          <div className="CoreCompetenciesBox2">
            <div className="CoreCompetenciesBox">
              Adaptability & Initiative
            </div>
            <div className="CoreCompetenciesBox">
              User-Centric
            </div>
            <div className="CoreCompetenciesBoxDouble">
              Collaboration & Communication
            </div>                        
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
