import './Certifications.css';

function Certifications() {
  return (
    <div id="Certifications" className="Certifications">
      <div className="Certifications-header">
        <h2 className="CertificationsTitle">
          Certifications
        </h2>
        <div className="CertificationsHeadline">
          <div className="CertificationsHeadline">
          <p>CompTIA A+</p>
            <a href="https://drive.google.com/file/d/1tXOoI8vpzPH06h4Y_JpyiAmYeWJ3gvmr/view?usp=share_link" alt="Newren Diploma" target="_blank" rel="noreferrer">
            <div className="CertificationsCompany">
              <p>Comprehensive Computer Technician</p> 
            </div> 
            <div className="CertificationsSpecial">
              <p>• Certificate ID: Zy9OXbX8</p>       
            </div> 
          </a>
        </div> 
        <div className="CertificationsHeadline">
          <p>CompTIA Network+ </p>
            <a href="https://drive.google.com/file/d/12LbYcGyNonlQJLVu7s-ts_SW4QspZ7YS/view?usp=share_link" alt="Newren Diploma" target="_blank" rel="noreferrer">
            <div className="CertificationsSpecial">
              <p>• Certificate ID: 2q0BVab1</p>       
            </div> 
          </a>
        </div>          
        </div>                        
      </div>          
    </div>
  );
}

export default Certifications;
