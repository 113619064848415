import React, {useState, useEffect} from 'react'
import CNlogo from '../Images/CNLogo3.png';
import './Navbar.css';
import Education from '../Pages/Education.js';
import Certifications from '../Pages/Certifications.js';
//import Portfolio from '../Pages/Portfolio.js';
// import Hobbies from '../Pages/Hobbies.js';
import Work from '../Pages/Work.js';

function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false)
    
    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
      }
    
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWidth)
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    
      }, [])

    return (

        <nav className="Navbar">
            <div className="NavbarName">
                <a href="/">
                  <img src={CNlogo} className="CNLogo" alt="CNlogo" />
                </a>
                <a href="/">Courtney Newren</a>
            </div>
            {(toggleMenu || screenWidth > 900) && (
                <ul className="list">
                <a onClick={Work} href="/Work" className="items">Work Experience</a>
                <a onClick={Education} href="/Education" className="items">Education</a>
                <a onClick={Certifications} href="/Certifications" className="items">Certifications</a>
                {/* <a onClick={toggleNav} href="/Portfolio" className="items">Portfolio</a> */}
                {/* <a onClick={Hobbies} href="/Hobbies" className="items">Hobbies & Skills</a> */}
                </ul>
                )}
            <button onClick={toggleNav} className="btn">≡</button>
            </nav>       

    );
}




export default Navbar;


