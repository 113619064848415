import './Education.css';

function Education() {
  return (
    <div id="Education" className="Education">
      <div className="Education-header">
        <h2 className="EducationTitle">
          Education
        </h2>
        <div className="EducationHeadline">
          <p>Colorado State University - Global</p>
            <div className="EducationCompany">
              <p>Master of Science in Data Analytics</p> 
            </div> 
            <div className="EducationSpecial">
              <p>• Specialization in Applied Business Management</p>   
            </div>             
        </div>   
        <div className="EducationHeadline">
          <p>Colorado State University - Global</p>
            <a href="https://drive.google.com/file/d/1OEiJ4NvlGbetv6JTv5HRY7iBG-UZNitM/view?usp=share_link" alt="Newren Diploma" target="_blank" rel="noreferrer">
            <div className="EducationCompany">
              <p>Bachelor of Science in Applied Data Science</p> 
            </div> 
            <div className="EducationSpecial">
              <p>• Specialization in Computer Engineering</p>   
              <p>• Magna Cum Laude</p>      
            </div> 
          </a>
        </div>                          
      </div>          
    </div>
  );
}

export default Education;
