import './Work.css';

function Work() {
  return (
    <div id="Work" className="Work">
      <div className="Work-header">
        <div className="WorkTitle">
          Work Experience
        </div>
        <div className="WorkHeadline">
          <p>Software Developer</p>
          <p className="WorkCompany">Royal Splendor Travel</p>        
          <div className="WorkDate">
            <p>Jul. 2023 - Present</p>
          </div>
          <div className="WorkDetails">
            <p>• Develops and designs a user-friendly AWS-based API in React, achieving a 96.2% “highly-satisfied” rate among senior citizen clientele.</p>
            <p>• Implements a secure, high-volume, scalable MySQL database resulting in up to 40% fewer client requests each month.</p>
            <p>• Develops analytic-based featured itinerary suggestions resulting in between a 6% and 14% increase in sales since implementation.</p>
          </div>
        </div>
        <div className="WorkHeadline">
          <p>Software Developer</p>
          <p className="WorkCompany">Courtney Software Solutions LLC</p>        
          <div className="WorkDate">
            <p>Mar. 2020 - Present</p>
          </div>
          <div className="WorkDetails">
            <p>• Develops, designs, and maintains websites in React.js, Node.js, HTML, and CSS with 99.99% uptime or better in accordance with client needs and design ideas.</p>
            <p>• Creates, scales, and optimizes Node.js bots with configurable security and automation options by implementing optimization techniques and user feedback requests that results in an ~10% increase in active daily users per week. </p>
          </div>
        </div>
        <div className="WorkHeadline">
          <p>General Manager</p>
          <p className="WorkCompany">Park West Gallery</p>  
          <div className="WorkDate">
            <p>Feb. 2017 - Mar. 2020</p>
          </div>
          <div className="WorkDetails">
            <p>• Strong sales professional and public speaker, consistently driving sales with a 10% - 30% increase in revenue on underperforming ships.</p>
            <p>• Led multicultural teams on cruise ships, adept at fostering collaboration and effectively creating and communicating goals.</p>
          </div>
        </div>
        <div className="WorkHeadline">
          <p>Shift Leader</p>
          <p className="WorkCompany">Red Robin</p>  
          <div className="WorkDate">
            <p>Jun. 2013 - Jan. 2017</p>
          </div>
          <div className="WorkDetails">
            <p>• Promoted from hostess to waitress and further promoted to shift leader within a six-month period.</p>
            <p>• Managed dozens of team members and ensured guest satisfaction in a high-volume environment with enthusiasm.</p>
          </div>
        </div>   
        <div className="WorkHeadline">
          <p><u>Technical Skills</u></p>
          <div className="WorkDetails">
            <p>• <b>Languages</b>: JavaScript, HTML, CSS, TypeScript, C++.</p>
            <p>• <b>Environments/Libraries</b>: Node, React, Express, AWS, Google Cloud, MATLAB, MySQL, MongoDB</p>
            <p>• <b>Tools</b>: Git / GitHub, Visual Studio Code, Docker, Blender</p>
          </div>
        </div>                          
      </div>
    </div>
  );
}

export default Work;
